import React from "react";
import styled from "@emotion/styled";

const StyledSection = styled.section`
  position: relative;
  height: 100vh;
`;

const WorkSection = () => {
  return (
    <StyledSection>
      <div></div>
    </StyledSection>
  );
};

export default WorkSection;
