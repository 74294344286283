import React from "react";
import styled from "@emotion/styled";

const StyledMain = styled.main`
  section {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    height: 100vh;

    z-index: 0;
    display: flex;
    overflow: hidden;
    // width: 100%;
    max-width: 100%;
    padding: 8em 2em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e5e5e5;
    .projects__category {
      mix-blend-mode: exclusion;
      display: flex;
      overflow: hidden;
      width: 100vw;
      height: 100%;
      align-items: center;
      .projects__track {
        display: flex;
        align-items: center;
        .track-item {
          display: flex;
          flex: 0 0 auto;
          .track-item-title {
            margin-right: 4vw;
            margin-left: 4vw;
            font-family: ${(props) => props.theme.fonts.grotta};
            color: ${(props) => props.theme.colors.white};
            font-size: 13.7em;
            line-height: 0.9em;
            letter-spacing: 0.02em;
            text-transform: uppercase;
          }
        }
      }
    }
  }
`;

const WorkHero = ({ projectspage }) => {
  return (
    <>
      <StyledMain>
        <section>
          <div className="">
            <div className="">
              <p className="">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We
                ask questions, listen and advise our clients so that—together—we
                can create the best custom solutions possible. Here are a few
                examples of fabulous results.
              </p>
            </div>
          </div>
          <div className="projects__category">
            <div className="projects__track">
              <div className="track-item">
                <h2 className="track-item-title">
                  {projectspage.data.title.text}
                </h2>
              </div>
              <div className="track-item">
                <h2 className="track-item-title">
                  {projectspage.data.title.text}
                </h2>
              </div>
              <div className="track-item">
                <h2 className="track-item-title">
                  {projectspage.data.title.text}
                </h2>
              </div>
              <div className="track-item">
                <h2 className="track-item-title">
                  {projectspage.data.title.text}
                </h2>
              </div>
              <div className="track-item">
                <h2 className="track-item-title">
                  {projectspage.data.title.text}
                </h2>
              </div>
              <div className="track-item">
                <h2 className="track-item-title">
                  {projectspage.data.title.text}
                </h2>
              </div>
              <div className="track-item">
                <h2 className="track-item-title">
                  {projectspage.data.title.text}
                </h2>
              </div>
              <div className="track-item">
                <h2>{projectspage.data.title.text}</h2>
              </div>
            </div>
          </div>
        </section>
      </StyledMain>
    </>
  );
};

export default WorkHero;
