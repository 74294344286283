import React from "react";
import { graphql } from "gatsby";
import WorkHero from "../components/templates/WorkHero";
import WorkSection from "../components/templates/WorkSection";
import Layout from "../components/general/Layout";

const ProjectsPage = ({ data }) => {
  const projectspage = data.prismicProjects;
  return (
    <>
      <Layout>
        <WorkHero projectspage={projectspage} />
        <WorkSection />
      </Layout>
    </>
  );
};

export const query = graphql`
  query projectsPageQuery($lang: String) {
    prismicProjects(lang: { eq: $lang }) {
      data {
        title {
          text
        }
      }
      lang
      type
      alternate_languages {
        type
        lang
      }
    }
  }
`;

export default ProjectsPage;
